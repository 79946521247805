<template>
  <div>
    <div class="backgroundOV">
    </div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge">Operationsverständnis</v-card-title>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller">Was erwartet Sie im Inhaltsbereich
          Operationsverständnis?</v-card-title>
        <v-card-text>
          Dem Operationsverständnis liegen drei wesentliche Aspekte zugrunde.
          Diese gelten weithin als Grundlage zur Ausbildung eines umfassenden
          Operationsverständnisses. Daher wird im Hintergrundwissen auf diese
          drei Bereiche eingegangen:
          <ol>
            <li>Grundvorstellungen besitzen</li>
            <li>Darstellungen vernetzen</li>
            <li>Aufgabenbeziehungen nutzen</li>
          </ol>
            Im Anschluss wir werden an einigen Schüler:innenbeispielen
            exemplarisch Diagnose- und Förderungsaufgaben an Sie gestellt, sodass
            Sie die Facetten des Hintergrundwissens anwenden können.
            <br/>
          Den Abschluss bildet eine Kompetenzübersicht, sodass noch einmal transparent ist,
            was im Inhaltsbereichs Operationsverständnis zentral ist.
        </v-card-text>
      </v-card>

      <br />

      <br />

      <v-card outlined>
        <v-card-title>Weitere Ressourcen
        </v-card-title>
        <v-card-text>
          Wenn Sie sich weiterführend mit dem Thema Operationsverständnis auseinandersetzen möchten, können Sie auch
          folgende Angebote der Plattform KIRA nutzen.
          <br />
          <ul style="list-style-type: none; padding-left: 10px">
            <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/795" target="_blank"><v-icon
                  left>mdi-open-in-new</v-icon>KIRA: Operationsverständnis</a></li>
            <li style=" margin: 0px 0px;"><a href="https://kira.dzlm.de/node/800" target="_blank"><v-icon
                  left>mdi-open-in-new</v-icon>KIRA: Operationen</a></li>
          </ul>
        </v-card-text>
      </v-card>

      <v-row no-gutters justify="center" align="center">
        <p class="white--text" style="z-index: 1; padding-top: 15px">
          Im unteren Bereich finden Sie die Navigation.
        </p>
      </v-row>
      <v-row no-gutters justify="center" align="center">
        <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
      </v-row>
      <AppBottomNavOV next="/operationsverstaendnis/hintergrundwissen" />
    </div>
  </div>
</template>

<script>
import AppBottomNavOV from "@/common/AppBottomNavOV";
export default {
  components: {
    AppBottomNavOV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}

#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
